<template>
  <div class="pa-1 d-flex flex-column justify-space-between align-start">
    <v-dialog v-model="dialogEmail" persistent width="35vw">
      <v-card>
        <v-card-title>Envio de e-mail com anexo!</v-card-title>
        <v-card-subtitle>Premiação Trimestral</v-card-subtitle>
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <v-text-field
              :readonly="aguardarEnvio"
              v-model="emailEnvio"
              label="Digite o(s) email(s) para enviar"
              dense
            ></v-text-field>
            <v-progress-circular
              class="ml-3"
              v-if="aguardarEnvio"
              :width="3"
              color="green"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="aguardarEnvio"
            class="error"
            @click="dialogEmail = false"
            x-small
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="aguardarEnvio"
            class="success"
            @click="handleEnviaEmail"
            x-small
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAguarde" persistent width="20vw">
      <v-card>
        <v-card-title>Buscando dados.</v-card-title>
        <v-card-subtitle>Aguarde...</v-card-subtitle>
        <v-card-text>
          <div class="d-flex justify-center align-center">
            <v-progress-circular
              :size="25"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="d-flex align-center" style="width: 80vw;">
      <span>Olá {{ user.name }}, que bom ver você por aqui!!</span>

      <v-col cols="2" class="ma-2">
        <v-select
          class="rounded-lg"
          v-model="anoEscolhido"
          :loading="loadingAnos"
          :items="anos"
          label="Escolha o ano"
          @change="buscaPessoas"
          outlined
          dense
        ></v-select>
      </v-col>

      <v-col cols="5" class="ma-2">
        <v-autocomplete
          class="rounded-lg"
          v-model="vendedor"
          item-text="text"
          item-value="value"
          :items="vendedores"
          label="Escolha o vendedor"
          :disabled="vendedores.length <= 0"
          outlined
          dense
        ></v-autocomplete>
      </v-col>

      <v-col cols="2" class="ma-2">
        <v-select
          class="rounded-lg"
          v-model="trimestre"
          :items="[
            { text: '1 trimestre', value: '0101-0331' },
            { text: '2 trimestre', value: '0401-0630' },
            { text: '3 trimestre', value: '0701-0930' },
            { text: '4 trimestre', value: '1001-1231' },
          ]"
          label="Escolha o Trimestre"
          outlined
          dense
          @change="calcula"
        ></v-select>
      </v-col>

      <v-btn
        icon
        color="green"
        class="ml-5"
        @click="
          trimestre = null;
          gerouDados = false;
        "
        v-if="user.isAdmin"
      >
        <v-icon>mdi-cached</v-icon>
      </v-btn>
    </div>
    <div
      v-if="errorDados"
      class="d-flex flex-column justify-center align-center"
      style="width: 95vw; height: 78vh;"
    >
      <span class="title"
        >Desculpe,
        <v-icon size="36" color="red">mdi-emoticon-cry-outline</v-icon>
        {{ messageError }}.</span
      >
      <span class="title"> Favor procurar o DHO.</span>
    </div>
    <div v-else style="height: 82vh;overflow: scroll; width: 96vw;">
      <div
        v-if="isProspector"
        class="pa-2 ma-2"
        style="background: yellow; border-radius: 5px;"
      >
        <span
          class="d-flex justify-center align-center"
          style="font-size: .9rem; font-weight: bold;"
        >
          PRÉ-REQUISITO PARA ESTA APTO A RECEBER O PRÊMIO TRIMESTRAL: MÍNIMO DE
          90% DO OBJETIVO DAS VISITAS PROPOSTAS PARA O TRIMESTRE, AS QUAIS SERÃO
          VALIDADAS ATRAVÉS DO LANÇAMENTO DIÁRIO DA VISITAÇÃO.
        </span>
      </div>

      <div
        class="pa-2 ma-2"
        style="background: orange; border-radius: 5px;"
        v-if="gerouDados"
      >
        <span
          class="d-flex justify-center align-center"
          style="font-size: .9rem; font-weight: bold;"
        >
          O PRÊMIO TEM COMO OBJETIVO O ALCANCE DE OBJETIVOS DE FATURAMENTO
          TRIMESTRAL, NESTE CONTEXTO SERÃO PREMIADOS EXCLUSIVAMENTE OS
          COLABORADORES QUE TRABALHAREM DURANTE O TOTAL DO PERÍODO DOS TRÊS
          MESES QUE CONTEPLAM O TRIMESTRE EM VIGÊNCIA.
        </span>
      </div>

      <div class="d-flex justify-end align-center" v-if="gerouDados">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              large
              color="blue darken-2"
              class="mr-5 mt-5"
              v-bind="attrs"
              v-on="on"
              @click="handleGerarPdf(false)"
            >
              <v-icon>mdi-file-pdf-box</v-icon>
            </v-btn>
          </template>
          <span>Gerar PDF</span>
        </v-tooltip>
        <v-tooltip key="0" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              large
              color="red darken-2"
              class="mr-5 mt-5"
              v-bind="attrs"
              v-on="on"
              @click="chamaDialogEmail"
            >
              <v-icon>mdi-email-fast</v-icon>
            </v-btn>
          </template>
          <span>Enviar e-mail de premiação com o PDF.</span>
        </v-tooltip>
      </div>

      <!--Div para premiação Marketing Agendamentos-->
      <div class="pa-2" v-if="mktAgendamento">
        <v-card>
          <v-card-title
            class="justify-center align-center"
            style="background-color: #3949ab; color: white"
            >{{ labelBanner1 }} - Meta: {{ metaIndividualAgendamento }} - Prêmio
            Agendamento Leads
          </v-card-title>
        </v-card>

        <div class="pa-2">
          <v-card class="d-flex" color="grey lighten-2" flat height="100" tile>
            <v-card
              class="d-flex align-center justify-center"
              width="25vw"
              outlined
              tile
              color="orange"
              style="font-size: 0.9rem; font-weight: bold"
            >
              PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME META DE
              FATURAMENTO)
            </v-card>

            <div class="d-flex flex-column">
              <v-card
                class="d-flex align-center justify-center"
                width="60vw"
                height="50vh"
                outlined
                tile
              >
                <v-row style="margin-left: 0px">
                  <v-col
                    cols="6"
                    style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                  >
                    Até
                  </v-col>
                  <v-col cols="6" style="background-color: #ff8a65">
                    {{ metaAteIndividualAgendamento }}
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                class="d-flex align-center justify-center"
                width="60vw"
                height="50vh"
                outlined
                tile
                style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
              >
                NÃO HÁ PRÊMIAÇÃO
              </v-card>
            </div>

            <v-card
              class="d-flex align-center justify-center"
              width="7vw"
              outlined
              color="orange"
              tile
            >
              Base de Cálculo FL
            </v-card>
          </v-card>
        </div>
        <v-data-table
          class="elevation-1 tbResults"
          :headers="[
            {
              text: 'Faixa Inicial',
              align: 'start',
              value: 'fx_inicial',
            },
            {
              text: 'Faixa Final',
              align: 'start',
              value: 'fx_final',
            },
            {
              text: 'Prêmio',
              align: 'start',
              value: 'vl_premio',
              class: 'success--text title vlpremio',
            },
          ]"
          :items="calculoDadosComissaoAgendamento"
          hide-default-footer
        ></v-data-table>
      </div>

      <!--Div para premiação Marketing Agendamentos-->
      <div class="pa-2" v-if="mktComparecimento">
        <v-card>
          <v-card-title
            class="justify-center align-center"
            style="background-color: #3949ab; color: white"
            >{{ labelBanner1 }} - Meta: {{ metaIndividualComparecimento }} -
            Prêmio Comparecimento Agenda Leads
          </v-card-title>
        </v-card>

        <div class="pa-2">
          <v-card class="d-flex" color="grey lighten-2" flat height="100" tile>
            <v-card
              class="d-flex align-center justify-center"
              width="25vw"
              outlined
              tile
              color="orange"
              style="font-size: 0.9rem; font-weight: bold"
            >
              PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME META DE
              FATURAMENTO)
            </v-card>

            <div class="d-flex flex-column">
              <v-card
                class="d-flex align-center justify-center"
                width="60vw"
                height="50vh"
                outlined
                tile
              >
                <v-row style="margin-left: 0px">
                  <v-col
                    cols="6"
                    style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                  >
                    Até
                  </v-col>
                  <v-col cols="6" style="background-color: #ff8a65">
                    {{ metaAteIndividualComparecimento }}
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                class="d-flex align-center justify-center"
                width="60vw"
                height="50vh"
                outlined
                tile
                style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
              >
                NÃO HÁ PRÊMIAÇÃO
              </v-card>
            </div>

            <v-card
              class="d-flex align-center justify-center"
              width="7vw"
              outlined
              color="orange"
              tile
            >
              Base de Cálculo FL
            </v-card>
          </v-card>
        </div>
        <v-data-table
          class="elevation-1 tbResults"
          :headers="[
            {
              text: 'Faixa Inicial',
              align: 'start',
              value: 'fx_inicial',
            },
            {
              text: 'Faixa Final',
              align: 'start',
              value: 'fx_final',
            },
            {
              text: 'Prêmio',
              align: 'start',
              value: 'vl_premio',
              class: 'success--text title vlpremio',
            },
          ]"
          :items="calculoDadosComissaoComparecimento"
          hide-default-footer
        ></v-data-table>
      </div>

      <!--Div para premiação Individual e Administrativa-->
      <div class="pa-2 " v-if="premioInd || premioAdmin">
        <v-card>
          <v-card-title
            class="justify-center align-center"
            style="background-color: #3949ab; color: white"
            >{{ labelBanner1 }} - Meta: {{ metaIndividual }} - Prêmio Individual
            {{
              profissionalEscolhido.filter(e => e.ZA2_TIPO === "X").length > 0
                ? "indicação médica"
                : profissionalEscolhido.filter(e => e.ZA2_TIPO === "V").length >
                  0
                ? "Visitação médica"
                : ""
            }}</v-card-title
          >
        </v-card>

        <div class="pa-2 d-flex justify-space-between">
          <v-card class="d-flex" color="grey lighten-2" flat height="100" tile>
            <v-card
              class="d-flex align-center justify-center"
              width="25vw"
              outlined
              tile
              color="orange"
              style="font-size: 0.9rem; font-weight: bold"
            >
              PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME META DE
              FATURAMENTO)
            </v-card>

            <div class="d-flex flex-column">
              <v-card
                class="d-flex align-center justify-center"
                style="width:62vw"
                height="50vh"
                outlined
                tile
              >
                <v-row style="margin-left: 0px">
                  <v-col
                    cols="6"
                    style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                  >
                    Até
                  </v-col>
                  <v-col cols="6" style="background-color: #ff8a65">
                    {{ metaAteIndividual }}
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                class="d-flex align-center justify-center"
                height="50vh"
                outlined
                tile
                style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
              >
                NÃO HÁ PRÊMIAÇÃO
              </v-card>
            </div>

            <v-card
              class="d-flex align-center justify-center"
              width="7vw"
              outlined
              color="orange"
              tile
            >
              Base de Cálculo FL
            </v-card>
          </v-card>
        </div>
        <div class="pa-2 d-flex justify-space-between">
          <v-card
            class="d-flex align-center justify-center"
            width="25vw"
            outlined
            tile
            color="green"
            style="font-size: 0.9rem; font-weight: bold"
          >
            PARA DESEMPENHOS EXTRAORDINÁRIOS (CONFORME META DE FATURAMENTO
            INDIVIDUAL)
          </v-card>
          <v-data-table
            style="width:60vw"
            class="elevation-1"
            :headers="[
              {
                text: 'Faixa Inicial',
                align: 'start',
                value: 'fx_inicial',
              },
              {
                text: 'Faixa Final',
                align: 'start',
                value: 'fx_final',
              },
              {
                text: 'Prêmio',
                align: 'start',
                value: 'vl_premio',
                class: 'success--text title',
              },
            ]"
            :items="calculoDadosComissao"
            hide-default-footer
          >
            <template v-slot:[`item.vl_premio`]="{ item }">
              <span class="text-body-1 green--text text--darken-4">{{
                item.vl_premio
              }}</span> </template
            >"
          </v-data-table>

          <v-card
            class="d-flex align-center justify-center"
            width="7vw"
            outlined
            color="green"
            tile
          >
            Base de Cálculo FL
          </v-card>
        </div>
      </div>

      <!--Div para premiação opgrade-->
      <div class="pa-2" v-if="premioOp">
        <v-card>
          <v-card-title
            class="justify-center align-center"
            style="background-color: #3949ab; color: white"
            >{{ labelBanner1 }} - Meta: {{ metaIndividualOpGrade }} - Prêmio
            OpGrade</v-card-title
          >
        </v-card>

        <div class="pa-2 d-flex justify-space-between">
          <v-card class="d-flex" color="grey lighten-2" flat height="100" tile>
            <v-card
              class="d-flex align-center justify-center"
              width="25vw"
              outlined
              tile
              color="orange"
              style="font-size: 0.9rem; font-weight: bold"
            >
              PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME META DE
              FATURAMENTO)
            </v-card>

            <div class="d-flex flex-column">
              <v-card
                class="d-flex align-center justify-center"
                style="width:62vw"
                height="50vh"
                outlined
                tile
              >
                <v-row style="margin-left: 0px">
                  <v-col
                    cols="6"
                    style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                  >
                    Até
                  </v-col>
                  <v-col cols="6" style="background-color: #ff8a65">
                    {{ metaAteIndividualOpGrade }}
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                class="d-flex align-center justify-center"
                height="50vh"
                outlined
                tile
                style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
              >
                NÃO HÁ PRÊMIAÇÃO
              </v-card>
            </div>

            <v-card
              class="d-flex align-center justify-center"
              width="7vw"
              outlined
              color="orange"
              tile
            >
              Base de Cálculo FL
            </v-card>
          </v-card>
        </div>
        <div class="pa-2 d-flex justify-space-between">
          <v-card
            class="d-flex align-center justify-center"
            width="25vw"
            outlined
            tile
            color="green"
            style="font-size: 0.9rem; font-weight: bold"
          >
            PARA DESEMPENHOS EXTRAORDINÁRIOS (CONFORME META DE FATURAMENTO
            INDIVIDUAL)
          </v-card>
          <v-data-table
            style="width:60vw"
            class="elevation-1"
            :headers="[
              {
                text: 'Faixa Inicial',
                align: 'start',
                value: 'fx_inicial',
              },
              {
                text: 'Faixa Final',
                align: 'start',
                value: 'fx_final',
              },
              {
                text: 'Prêmio',
                align: 'start',
                value: 'vl_premio',
                class: 'success--text title',
              },
            ]"
            :items="calculoDadosComissaoOpGrade"
            hide-default-footer
          >
            <template v-slot:[`item.vl_premio`]="{ item }">
              <span class="text-body-1 green--text text--darken-4">{{
                item.vl_premio
              }}</span> </template
            >"
          </v-data-table>

          <v-card
            class="d-flex align-center justify-center"
            width="7vw"
            outlined
            color="green"
            tile
          >
            Base de Cálculo FL
          </v-card>
        </div>
      </div>

      <!--Div para premiação Equipe-->:
      <div v-if="premioEqp">
        <div
          class="pa-2 "
          v-for="item in dadosDoCalculoEquipe"
          :key="item.nome"
        >
          <v-card-title
            class="justify-center align-center"
            style="background-color: #3949ab; color: white"
            >Prêmio Trimestral - {{ item.nome }} - Meta
            {{ item.meta }}</v-card-title
          >

          <div class="pa-2 d-flex justify-space-between">
            <v-card
              class="d-flex"
              color="grey lighten-2"
              flat
              height="100"
              tile
            >
              <v-card
                class="d-flex align-center justify-center"
                width="25vw"
                outlined
                tile
                color="orange"
                style="font-size: 0.9rem; font-weight: bold"
              >
                PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME META DE
                FATURAMENTO)
              </v-card>

              <div class="d-flex flex-column">
                <v-card
                  class="d-flex align-center justify-center"
                  style="width:62vw"
                  height="50vh"
                  outlined
                  tile
                >
                  <v-row style="margin-left: 0px">
                    <v-col
                      cols="6"
                      style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                    >
                      Até
                    </v-col>
                    <v-col cols="6" style="background-color: #ff8a65">
                      {{ item.metaAte }}
                    </v-col>
                  </v-row>
                </v-card>
                <v-card
                  class="d-flex align-center justify-center"
                  height="50vh"
                  outlined
                  tile
                  style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
                >
                  NÃO HÁ PRÊMIAÇÃO
                </v-card>
              </div>

              <v-card
                class="d-flex align-center justify-center"
                width="7vw"
                outlined
                color="orange"
                tile
              >
                Base de Cálculo FL
              </v-card>
            </v-card>
          </div>
          <div class="pa-2 d-flex justify-space-between">
            <v-card
              class="d-flex align-center justify-center"
              width="25vw"
              outlined
              tile
              color="green"
              style="font-size: 0.9rem; font-weight: bold"
            >
              PARA DESEMPENHOS EXTRAORDINÁRIOS (CONFORME META DE FATURAMENTO
              INDIVIDUAL)
            </v-card>
            <v-data-table
              style="width:60vw"
              class="elevation-1"
              :headers="[
                {
                  text: 'Faixa Inicial',
                  align: 'start',
                  value: 'fx_inicial',
                },
                {
                  text: 'Faixa Final',
                  align: 'start',
                  value: 'fx_final',
                },
                {
                  text: 'Prêmio',
                  align: 'start',
                  value: 'vl_premio',
                  class: 'success--text title',
                },
              ]"
              :items="item.calculo"
              hide-default-footer
            >
              <template v-slot:[`item.vl_premio`]="{ item }">
                <span class="text-body-1 green--text text--darken-4">{{
                  item.vl_premio
                }}</span> </template
              >"
            </v-data-table>

            <v-card
              class="d-flex align-center justify-center"
              width="7vw"
              outlined
              color="green"
              tile
            >
              Base de Cálculo FL
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { add, sub } from "date-fns";
import dinero from "dinero.js";
import { VMoney } from "v-money";
import { mapState } from "vuex";

// const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

export default {
  name: "Comissoes",
  directives: { money: VMoney },
  data: () => {
    return {
      dialogEmail: false,
      gerouDados: false,
      dialogAguarde: false,
      isProspector: false,
      errorDados: false,
      premioEqp: false,
      premioOp: false,
      premioInd: false,
      mktComparecimento: false,
      metaIndividualComparecimento: 0,
      metaAteIndividualComparecimento: 0,
      calculoDadosComissaoComparecimento: [],
      metaIndividualAgendamento: 0,
      metaAteIndividualAgendamento: 0,
      calculoDadosComissaoAgendamento: [],
      mktAgendamento: false,
      prospeccaoVisitacao: false,
      metaIndividualVisitacao: 0,
      metaAteIndividualVisitacao: 0,
      calculoDadosComissaoVisitacao: [],
      prospeccaoIndicacao: false,
      metaIndividualIndicacao: 0,
      metaAteIndividualIndicacao: 0,
      calculoDadosComissaoIndicacao: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
      },
      loading: false,
      labelDivisaoindividual: null,
      labelDivisaoOpgrade: null,
      labelDivisaoEquipe: null,
      aguardarEnvio: false,

      emailEnvio: null,
      dadosFatOpgrade: {},
      dadosFatIndividual: {},
      totalPremioIndividual: 0,
      totalPremioOpGrade: 0,
      totalPremioEquipe: 0,
      totalPremioGeral: 0,
      metaAteIndividualOpGrade: null,
      valorTotalDetalhamentoOpGrade: null,
      totalGeralResumo: 0,
      headersResumo: [
        { text: "Ano / Trimestre", value: "ZAA_ANOTRI" },
        { text: "Área", value: "A3_ZZAREA" },
        { text: "Loja", value: "ZAA_LOJA" },
        { text: "Vendedor", value: "VENDEDOR" },
        { text: "Profissional", value: "ZAA_PROFIS" },
        { text: "Prêmio", value: "VALOR_PREMIO", align: "end" },
        {
          text: "Variação trimestre anterior",
          value: "VARIACAO",
          align: "center",
        },
        { text: "Ações", value: "action" },
      ],
      premioAdmin: false,
      headersResumoExcel: [
        { label: "Ano / Trimestre", field: "ZAA_ANOTRI" },
        { label: "Área", field: "A3_ZZAREA" },
        { label: "Loja", field: "ZAA_LOJA" },
        { label: "Vendedor", field: "VENDEDOR" },
        { label: "Profissional", field: "ZAA_PROFIS" },
        { label: "Prêmio", field: "ZAA_VALOR", align: "end" },
      ],
      itemsResumo: [],
      itemsResumoExcel: [],
      buscaResumo: null,
      numberofEquipes: 0,
      andamento: 0,
      anos: [],
      loadingAnos: true,
      titlePremioCalc: null,
      totalGeralEquipes: 0,
      tipoVendedor: null,
      headersEquipeDetVendedores: [
        { text: "Vendedor", align: "start", sortable: true, value: "VENDEDOR" },
        {
          text: "Valor Vendido",
          align: "end",
          sortable: true,
          value: "FATURAMENTO",
        },
      ],
      totalGeralPremio: 0,
      totalGeralPremioOpGrade: 0,
      totaldePessoas: 1,
      dadosDoCalculoEquipe: [],
      equipe: null,
      itemsRelatExcel: [],
      headersRelatExcel: [],
      nameRelatExcel: null,
      searchDetalhe: null,
      switchDetalhe: false,
      anoEscolhido: null,
      trimestre: null,
      vendedores: [],
      vendedor: null,
      labelBanner1: null,
      showSkelekton: false,
      dialogFecharResumo: false,
      resumoFechado: true,
      dialogRefinamento: false,
      refineValue: "",
      obsRefinamento: "",
      totalRefinamento: 0,
      textDisabled: "grey--text text--lighten-3",
      showDados: false,
      numberLines: 0,
      valorTotalDetalhamento: null,
      headersDetalhamento: [],
      itemsDetalhamento: [],
      calculoDadosComissao: [],
      calculoDadosComissaoOpGrade: [],
      metaIndividual: null,
      metaAteIndividual: null,
      metaIndividualOpGrade: null,
      dialogInfo: false,
      tituloCard: null,
      mensagem: null,
      metaEquipe: null,
      metaAteEquipe: null,
      countEquipes: 0,
      calculoDadosComissaoEquipe: [],
      dadosVendedoresGeral: [],
      profissionalEscolhido: [],
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
      faturamentoIndividual: state => state.premiacao.faturamentoIndividual,
      dadosCalculoIndividual: state => state.premiacao.dadosCalculoIndividual,
    }),
    trimestreEscolhido() {
      if (this.trimestre.substring(0, 2) == "01") return "01";
      if (this.trimestre.substring(0, 2) == "04") return "02";
      if (this.trimestre.substring(0, 2) == "07") return "03";
      if (this.trimestre.substring(0, 2) == "10") return "04";
      return "";
    },
  },
  methods: {
    toCurrency(number) {
      return number.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    validaCor(valor) {
      if (valor <= 25) {
        return "red accent-4";
      }
      if (valor > 25 && valor <= 50) {
        return "yellow accent-4";
      }
      if (valor > 50 && valor <= 75) {
        return "blue accent-4";
      }
      if (valor > 75) {
        return "green accent-4";
      }
    },
    async deletaPremio(item) {
      const dadosTabAux = {
        ZAA_ANOTRI: item.ZAA_ANOTRI,
        ZAA_CODVEN: item.ZAA_CODVEN,
        D_E_L_E_T_: "*",
        R_E_C_D_E_L_: item.RECNO,
      };

      const urlAux = `${process.env.VUE_APP_BASE_API_URL}/addAuxPremio`;
      await axios.post(urlAux, dadosTabAux);

      this.newCalculaResumo();
    },
    async handleRefine() {
      this.loading = true;
      const anoTrimestre = `${this.anoEscolhido}${this.trimestreEscolhido}`;
      const vendedor = this.vendedor;

      this.totalRefinamento =
        Number(this.refineValue.replace(/[^-\d]/g, "")) / 100;

      const url = `${process.env.VUE_APP_BASE_API_URL}/refinamentos/${anoTrimestre}/${vendedor}`;
      const dados = {
        ZA6_VALOR: this.totalRefinamento,
        ZA6_OBS: this.obsRefinamento,
        ZA6_USRALT: this.user.usr_protheus,
      };

      try {
        await axios.put(url, dados);

        this.dialogRefinamento = false;
        this.refineValue = "";

        this.totalPremioGeral =
          this.totalPremioIndividual +
          this.totalPremioOpGrade +
          this.totalPremioEquipe +
          this.totalRefinamento;

        const dadosAtualizados = {
          ZAA_ANOTRI: `${this.anoEscolhido}${this.trimestreEscolhido}`,
          ZAA_CODVEN: this.vendedor,
          ZAA_VALOR: this.totalPremioGeral,
        };
        const urlAux = `${process.env.VUE_APP_BASE_API_URL}/addAuxPremio`;
        await axios.post(urlAux, dadosAtualizados);

        this.$toast.success("Refinamento gravado com sucesso!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("Erro na inclusão do refinamento", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      this.loading = false;
    },
    async handleFecharResumo() {
      this.loading = true;
      const anoTrimestre = `${this.anoEscolhido}${this.trimestreEscolhido}`;

      const url = `${process.env.VUE_APP_BASE_API_URL}/resumos/${anoTrimestre}/close`;

      try {
        await axios.put(url);
        this.resumoFechado = true;
        this.dialogFecharResumo = false;

        this.$toast.success("Resumo fechado!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("Não foi possível fechar o resumo.", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      this.loading = false;
    },

    async newCalculaResumo() {
      if (this.vendedores.length <= 0) return;

      if (!this.trimestre) return;

      this.itemsResumo = [];

      let trimestre = "";
      let trimestreAnterior = "";

      if (this.trimestre.split("-")[0].substr(0, 2) == "01") {
        trimestre = `${this.anoEscolhido}01`;
        trimestreAnterior = `${this.anoEscolhido - 1}04`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "04") {
        trimestre = `${this.anoEscolhido}02`;
        trimestreAnterior = `${this.anoEscolhido}01`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "07") {
        trimestre = `${this.anoEscolhido}03`;
        trimestreAnterior = `${this.anoEscolhido}02`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "10") {
        trimestre = `${this.anoEscolhido}04`;
        trimestreAnterior = `${this.anoEscolhido}03`;
      }

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ZMA_DESCRI,A3_ZZAREA,A3_NOME,ZAA.ZAA_ANOTRI,ZAA.ZAA_CODVEN,ZAA.ZAA_LOJA,ZAA.ZAA_PROFIS,ZAA.ZAA_VALOR,ZAAA.ZAA_VALOR ZAA_VALORANT,ZAA.R_E_C_N_O_ RECNO,ZAA.ZAA_FECHAD,ZA6_OBS,ZA6_VALOR`,
        INNER: `INNER JOIN SA3010 SA3 WITH(NOLOCK) ON
                              SA3.D_E_L_E_T_=' '
                              AND A3_COD=ZAA.ZAA_CODVEN
                LEFT JOIN ZMA010 ZMA WITH(NOLOCK) ON
                              ZMA.D_E_L_E_T_=' '
                              AND ZMA_IDAREA=A3_ZZAREA
                LEFT JOIN ZAA010 ZAAA WITH(NOLOCK) ON
                              ZAAA.D_E_L_E_T_=' '
                              AND ZAAA.ZAA_CODVEN = ZAA.ZAA_CODVEN
                              AND ZAAA.ZAA_ANOTRI = '${trimestreAnterior}'
                LEFT JOIN ZA6010 ZA6 WITH(NOLOCK) ON
                              ZA6.D_E_L_E_T_=' '
                              AND ZA6.ZA6_CODVEN = ZAA.ZAA_CODVEN
                              AND ZA6.ZA6_ANOTRI = ZAA.ZAA_ANOTRI `,
        WHERE: `ZAA.ZAA_ANOTRI='${trimestre}' ORDER BY A3_NOME`,
        TABELA: `ZAA`,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const premio = await axios.post(url, dados);

      this.totalGeralResumo = 0;
      this.resumoFechado = !premio.data.some(e => e.ZAA_FECHAD !== "S");
      console.log("resumoFechado", this.resumoFechado);

      premio.data.map(e => {
        e.VALOR_PREMIO = e.ZAA_VALOR.toLocaleString("pt-BR", {
          currency: "BRL",
          style: "currency",
        });
        this.totalGeralResumo += e.ZAA_VALOR;
        e.VENDEDOR = `${e.ZAA_CODVEN}-${e.A3_NOME}`;
        e.A3_ZZAREA = `${e.A3_ZZAREA}-${e.ZMA_DESCRI}`;
        e.VARIACAO = Number(
          ((e.ZAA_VALOR || 0) - (e.ZAA_VALORANT || 0)) /
            (e.ZAA_VALOR || 1).toFixed(2),
        );
        this.obsRefinamento = e.ZA6_OBS;
        this.refineValue = e.ZA6_VALOR
          ? e.ZA6_VALOR.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : "";
      });

      this.itemsResumo = premio.data;
      this.itemsResumoExcel = premio.data;
      this.totalGeralResumo = this.totalGeralResumo.toLocaleString("pt-BR", {
        currency: "BRL",
        style: "currency",
      });
    },
    async calculaResumo() {
      this.itensResumo = [];

      this.itemsResumo = this.dadosVendedoresGeral.map(e => {
        let META;
        let METAFLOAT = 0;
        if (this.trimestre === "0101-0331") {
          META = e.ZA2_META1.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          METAFLOAT = e.ZA2_META1;
        }
        if (this.trimestre === "0401-0630") {
          META = e.ZA2_META2.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          METAFLOAT = e.ZA2_META2;
        }
        if (this.trimestre === "0701-0930") {
          META = e.ZA2_META3.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          METAFLOAT = e.ZA2_META3;
        }
        if (this.trimestre === "1001-1231") {
          META = e.ZA2_META4.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          METAFLOAT = e.ZA2_META4;
        }
        return {
          VENDEDOR: `${e.ZA2_CODVEN}-${e.A3_NOME.trim()}`,
          CARGO: e.ZA9_DESCRI,
          TIPO: e.ZA2_TIPO === "I" ? "INDIVIDUAL" : "EQUIPE",
          META,
          METAFLOAT,
          REALIZADO: 0,
          ...e,
        };
      });

      await Promise.all(
        this.itemsResumo.map(async item => {
          if (item.ZA2_TIPO === "I") {
            item.REALIZADO = await this.calculaResumoIndividual(item);
            if (item.REALIZADO <= 0) {
              item.REALIZADO = "-";
              item.REALIZADOCURR = "-";
              item.PERCENT = 0;
            } else {
              item.REALIZADOCURR = item.REALIZADO.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              item.PERCENT = ((item.REALIZADO * 100) / item.METAFLOAT).toFixed(
                2,
              );
            }
          }
          if (item.ZA2_TIPO === "E") {
            item.REALIZADO = await this.calculaResumoEquipe(item);
            if (item.REALIZADO <= 0) {
              item.REALIZADO = "-";
              item.REALIZADOCURR = "-";
              item.PERCENT = 0;
            } else {
              item.REALIZADOCURR = item.REALIZADO.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              item.PERCENT = ((item.REALIZADO * 100) / item.METAFLOAT).toFixed(
                2,
              );
            }
          }
          if (item.METAFLOAT <= 0) {
            item.REALIZADO = "-";
            item.REALIZADOCURR = "-";
            item.PERCENT = 0;
          }
        }),
      );
    },
    async calculaResumoEquipe(vendedor) {
      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ZA7_CODVEN`,
        INNER: `INNER JOIN ZA8010 ZA8 WITH(NOLOCK) ON
                            ZA8.D_E_L_E_T_=' '
                            AND ZA8_IDREG=ZA2_ID

                INNER JOIN ZA7010 ZA7 WITH(NOLOCK) ON
                            ZA7.D_E_L_E_T_=' '
                            AND ZA7_IDCAB=ZA8_IDEQP `,
        WHERE: `ZA2_ID='${vendedor.ZA2_ID}' AND ZA2_ANO='${this.anoEscolhido}' AND ZA2_TIPO='E'`,
        TABELA: `ZA2`,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const vendedoresEquipes = await axios.post(url, dados);

      let vendedores = "";

      vendedoresEquipes.data.map(e => {
        vendedores += `'${e.ZA7_CODVEN}',`;
      });

      vendedores = vendedores.substring(0, vendedores.length - 1);

      const datas = this.trimestre.split("-");
      const dtInicial = `${this.anoEscolhido}${datas[0]}`;
      const dtFinal = `${this.anoEscolhido}${datas[1]}`;

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "SUM(FATURAMENTO_LIQUIDO) FATURAMENTO",
        WHERE: `CHAVE_VENDEDOR IN (${vendedores}) AND DATA_EMISSAO BETWEEN '${dtInicial}' AND '${dtFinal}' `,
        TABELA: "DB_V_RECEITAS_BI",
      };

      const faturamento = await axios.post(url, dados);

      if (faturamento.data.length > 0) {
        return faturamento.data[0].FATURAMENTO;
      } else {
        return 0;
      }
    },
    async calculaResumoIndividual(vendedor) {
      const datas = this.trimestre.split("-");
      const dtInicial = `${this.anoEscolhido}${datas[0]}`;
      const dtFinal = `${this.anoEscolhido}${datas[1]}`;

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `SUM(FATURAMENTO_LIQUIDO) FATURAMENTO_LIQUIDO`,
        WHERE: ` CHAVE_VENDEDOR='${vendedor.ZA2_CODVEN}' AND DATA_EMISSAO BETWEEN '${dtInicial}' AND '${dtFinal}' `,
        TABELA: `DB_V_RECEITAS_BI`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const realizado = await axios.post(url, dados);

      if (realizado.data.length > 0) {
        return realizado.data[0].FATURAMENTO_LIQUIDO;
      } else {
        return 0;
      }
    },
    setVendedor(item) {
      this.profissionalEscolhido = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === item,
      );

      this.showDados = false;
      this.tipoVendedor = item.A3_ZTIPO;
      this.labelBanner1 = `${item.Z0_DESC} - ${item.A3_NOME} - ${item.ZMA_DESCRI}`;
    },
    async calcula() {
      this.gerouDados = false;
      this.dialogAguarde = false;
      this.isProspector = false;
      this.premioEqp = false;
      this.premioOp = false;
      this.premioInd = false;
      this.calculoDadosComissaoAgendamento = [];
      this.calculoDadosComissaoComparecimento = [];
      this.mktAgendamento = false;
      this.mktComparecimento = false;
      this.obsRefinamento = "";
      this.dadosDoCalculoEquipe = [];
      this.premioAdmin = false;
      this.prospeccaoIndicacao = false;
      this.metaIndividualIndicacao = 0;
      this.metaAteIndividualIndicacao = 0;
      this.calculoDadosComissaoIndicacao = [];
      this.prospeccaoVisitacao = false;
      this.metaIndividualVisitacao = 0;
      this.metaAteIndividualVisitacao = 0;
      this.calculoDadosComissaoVisitacao = [];
      this.totalGeralPremio = 0;
      this.totalPremioIndividual = 0;
      this.totalPremioOpGrade = 0;
      this.totalPremioEquipe = 0;
      this.totalRefinamento = 0;
      this.totaldePessoas = 1;
      this.countEquipes = 0;

      if (!this.anoEscolhido || !this.trimestre || !this.vendedor) {
        this.dialogInfo = true;
        this.tituloCard = "Erro";
        this.dialogAguarde = false;
        this.mensagem =
          "Escolha o ano, trimestre e vendedor para fazer o cálculo!";
        return;
      }

      this.showSkelekton = true;
      this.showDados = false;

      const url1 = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      let dados1 = {
        FIELDS: "A3_ZTIPO,A3_FILREF,A3_NOME",
        WHERE: `A3_COD='${this.vendedor}' `,
        TABELA: "SA3",
      };

      const dadosVendedor = await axios.post(url1, dados1);

      if (dadosVendedor.data.length <= 0) {
        this.dialogAguarde = false;
        this.$toast.error(
          "Não achei os dados deste vendedor. Tipo e Filial de Referência!",
          {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          },
        );
        return;
      }

      const profissional = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === this.vendedor,
      );

      if (profissional.length <= 0) {
        this.dialogAguarde = false;
        this.$toast.error("Sem identificação do profissional!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      const dados = {
        FIELDS: "ZA9_COD",
        WHERE: `ZA9_COD='${profissional[0].ZA2_PROFIS}'`,
        TABELA: "ZA9",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const profissionais = await axios.post(url, dados);

      if (profissionais.data.length <= 0) {
        this.$toast.error("Código do profissional não encontrado!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      this.showSkelekton = true;
      this.showDados = false;

      this.labelBanner1 = ``;

      this.itemsDetalhamento = [];
      this.calculoDadosComissao = [];
      this.metaAteIndividual = null;
      this.numberofEquipes = 0;
      this.dadosDoCalculoEquipe = [];
      this.totalGeralPremioOpGrade = 0;
      this.metaIndividualOpGrade = 0;
      this.calculoDadosComissaoOpGrade = [];

      for await (const item of profissional) {
        this.dialogAguarde = true;
        if (item.ZA2_TIPO === "E") {
          this.labelBanner1 = `${
            item.ZA2_CODVEN
          }-${item?.A3_NOME.trim()} (${item?.ZA9_DESCRI.trim()})`;
        } else {
          this.labelBanner1 = `${item.ZA2_CODVEN}-${item?.A3_NOME.trim()}`;
        }
        if (item.ZA2_TIPO === "I") {
          this.premioInd = true;
          await this.premioIndividual(item);
        }

        if (item.ZA2_TIPO === "O") {
          this.premioOp = true;
          await this.premioOpgrade(item);
        }

        if (item.ZA2_TIPO === "E") {
          this.premioEqp = true;
          this.countEquipes++;
          const datas = this.trimestre.split("-");
          const dtInicial = `${this.anoEscolhido}${datas[0]}`;

          this.calculoDadosComissaoEquipe = [];
          this.metaEquipe = null;
          this.metaAteEquipe = null;

          const dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: `ZA8_IDEQP,ZA3_INDINI,ZA3_INDFIM,ZA3_TIPO,ZA3_PERC,ZA2_LIMITE,ZXG_QTDP,ZXG_PESO,ZA2_TIPO,ZA3_PBASE,ZA3_TIPOV,ZA2_TPMETA,ZXG_LOJA`,
            INNER: `  INNER JOIN ZXG010 ZXG WITH(NOLOCK) ON ZXG.D_E_L_E_T_=' ' AND ZXG_IDZA2=ZA2_ID AND ZXG_TRIMES='${Number(
              this.trimestreEscolhido,
            )}'
                      INNER JOIN ZA8010 ZA8 WITH(NOLOCK) ON ZA8.D_E_L_E_T_=' ' AND ZA8_IDREG=ZXG_ID
                      INNER JOIN ZA3010 ZA3 WITH(NOLOCK) ON ZA3.D_E_L_E_T_=' ' AND ZA3_IDREG=ZXG_ID `,
            WHERE: `ZA2_CODVEN='${item.ZA2_CODVEN}' AND ZA2_ANO='${this.anoEscolhido}' AND ZA2_TIPO='E' AND ZA2_ID='${item.ZA2_ID}'`,
            TABELA: `ZA2`,
          };

          this.titlePremioCalc = "Calculando prêmio equipe...";

          const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          let dadosComissaoEquipe = [];
          await axios
            .post(url, dados)
            .then(res => {
              dadosComissaoEquipe = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });

          if (dadosComissaoEquipe.length <= 0) {
            this.dialogInfo = true;
            this.tituloCard = "Erro";
            this.mensagem = "Não achei dados para o cálculo do prêmio EQUIPE!";
          }

          await this.calculoEquipe(dadosComissaoEquipe, dtInicial);
        }

        if (item.ZA2_TIPO === "A") {
          await this.premioAdm(item);
        }

        if (item.ZA2_TIPO === "F") {
          await this.premioMktAgendamento();
        }

        if (item.ZA2_TIPO === "G") {
          await this.premioMktComparecimento();
        }

        // if (item.ZA2_TIPO === "X") {
        //   await this.premioProspeIndica();
        // }

        // if (item.ZA2_TIPO === "V") {
        //   await this.premioProspeVisita();
        // }
      }

      /* prospector */
      for (const item of profissional) {
        if (item.ZA2_TIPO === "P") {
          this.isProspector = true;
          this.countEquipes++;

          this.calculoDadosComissaoEquipe = [];
          this.metaEquipe = null;
          this.metaAteEquipe = null;

          const dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: `ZA8_IDEQP,ZA3_INDINI,ZA3_INDFIM,ZA3_TIPO,ZA3_PERC,ZA2_LIMITE,ZXG_QTDP,ZXG_PESO,ZA2_TIPO,ZA3_PBASE,ZA3_TIPOV,ZA5_LOJA,ZA2_TPMETA,ZXG_LOJA`,
            INNER: `
                    INNER JOIN ZXG010 ZXG WITH(NOLOCK) ON ZXG.D_E_L_E_T_=' ' AND ZXG_IDZA2=ZA2_ID AND ZXG_TRIMES='${Number(
                      this.trimestreEscolhido,
                    )}'
                    INNER JOIN ZA8010 ZA8 WITH(NOLOCK) ON ZA8.D_E_L_E_T_=' ' AND ZA8_IDREG=ZXG_ID
                    INNER JOIN ZA3010 ZA3 WITH(NOLOCK) ON ZA8.D_E_L_E_T_=' ' AND ZA3_IDREG=ZXG_ID
                    INNER JOIN ZA5010 ZA5 WITH(NOLOCK) ON ZA5.D_E_L_E_T_=' ' AND ZA5_COD=ZA8_IDEQP `,
            WHERE: `ZA2_CODVEN='${item.ZA2_CODVEN}' AND ZA2_ANO='${this.anoEscolhido}' AND ZA2_TIPO='P' AND ZA2_ID='${item.ZA2_ID}'`,
            TABELA: `ZA2`,
          };

          this.titlePremioCalc = "Calculando prêmio equipe prospecção...";

          const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          let dadosComissaoEquipe = [];
          let dadosComissaoEquipeOld = [];
          await axios
            .post(url, dados)
            .then(res => {
              dadosComissaoEquipe = res.data;
              dadosComissaoEquipeOld = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });

          dadosComissaoEquipe = [
            ...new Set(dadosComissaoEquipe.map(e => e.ZA8_IDEQP)),
          ];

          let dadosParaPremioEquipe = [];

          for (const equipe of dadosComissaoEquipe) {
            const dadosDoCalculoAnterior = this.dadosDoCalculoEquipe
              .filter(e => e.nome.split("-")[0] === equipe)
              .map(e => e.calculo);

            let porc = 0;
            let premio = 0;

            dadosDoCalculoAnterior.map(e => {
              e.map(s => {
                if (s.vl_premioCurr > 0) {
                  porc = Number(s.fx_inicial.split("%")[0]);
                  premio = s.vl_premioCurr;
                }
              });
            });

            if (porc >= 100) {
              dadosParaPremioEquipe.push({
                premio,
                porc,

                idEquipe: equipe,
                dadosComissao: dadosComissaoEquipeOld.filter(
                  e => e.ZA8_IDEQP === equipe,
                ),
              });
            }
          }

          await this.calculoEquipeProspeccao(dadosParaPremioEquipe);
        }
      }

      this.showSkelekton = false;
      this.showDados = true;

      this.totalGeralPremio = this.totalGeralPremio.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });

      this.totalPremioGeral =
        this.totalPremioIndividual +
        this.totalPremioOpGrade +
        this.totalPremioEquipe +
        this.totalRefinamento;

      // não atualizar calculo do trimestre fechado
      url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const premioTrimestre = await axios.post(url, {
        FIELDS: "ZAA_FECHAD",
        WHERE: `ZAA_ANOTRI='${this.anoEscolhido}${this.trimestreEscolhido}'
            AND ZAA_CODVEN='${this.vendedor}'`,
        TABELA: "ZAA",
      });

      if (premioTrimestre.data[0]?.ZAA_FECHAD === "S") {
        this.showSkelekton = false;
        this.showDados = true;
        this.dialogAguarde = false;
        this.gerouDados = true;
        return;
      }

      //aqui vou gravar a tabela auxiliar para o total da premiação.
      const dadosTabAux = {
        ZAA_ANOTRI: `${this.anoEscolhido}${this.trimestreEscolhido}`,
        ZAA_CODVEN: profissional[0].ZA2_CODVEN,
        ZAA_AREA: "",
        ZAA_LOJA: profissional[0].ZA2_LOJA,
        ZAA_PROFIS: profissional[0].ZA9_DESCRI
          ? profissional[0].ZA9_DESCRI.substring(0, 80)
          : "",
        ZAA_VALOR: this.totalPremioGeral,
      };
      const urlAux = `${process.env.VUE_APP_BASE_API_URL}/addAuxPremio`;
      await axios.post(urlAux, dadosTabAux);

      this.showSkelekton = false;
      this.showDados = true;
      this.gerouDados = true;
      this.dialogAguarde = false;
    },

    async premioMktAgendamento() {
      this.mktAgendamento = true;
      this.metaIndividualAgendamento = 0;
      this.metaAteIndividualAgendamento = 0;
      this.calculoDadosComissaoAgendamento = [];

      let url = `${process.env.VUE_APP_BASE_API_URL}/metricasAgendamento`;

      this.titlePremioCalc = "Buscando Agendamentos...";

      const metricas = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        tipo: "A",
      });

      if (!metricas || metricas.status !== 200) {
        console.log("erro");
        return;
      }

      console.log(metricas);

      url = `${process.env.VUE_APP_BASE_API_URL}/dadosPremio`;

      const dadosPremio = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
        tipo: "F",
      });

      if (!dadosPremio || dadosPremio.status !== 200) {
        console.log("erro");
        return;
      }

      let dadosComissao = dadosPremio.data;
      this.calculoDadosComissao = [];
      this.metaIndividualAgendamento = 0;
      this.metaAteIndividualAgendamento = 0;
      this.labelDivisaoIndividual = null;

      let calculoDados = [];

      dadosComissao.forEach(e => {
        let valorInicial = Math.round(
          ((metricas.data.meta * e.ZA3_INDINI) / 100).toFixed(2),
        );

        let valorFinal = Math.round(
          ((metricas.data.meta * e.ZA3_INDFIM) / 100).toFixed(2),
        );

        valorFinal -= 1;

        if (this.metaAteIndividualAgendamento <= 0) {
          this.metaAteIndividualAgendamento = valorInicial - 1;
        }

        let range = Number(
          ((metricas.data.agendamentos * 100) / metricas.data.meta).toFixed(2),
        );

        let tagMax = "";
        if (range > e.ZA2_LIMITE) {
          tagMax = "Excedeu o limite de 120%";
        }

        let valorBase = Number(((e.ZXG_VLPRM * e.ZXG_PESO) / 100).toFixed(2));

        let valorPremio = Number(((valorBase * e.ZA3_PERC) / 100).toFixed(2));

        valorPremio = 50 - (valorPremio % 50) + valorPremio;

        this.totalGeralPremio += valorPremio;
        this.totalPremioIndividual += valorPremio;

        let valorPre = Number(valorPremio.toFixed(2).replace(".", ""));

        calculoDados.push({
          vl_vendido: metricas.data.agendamentos,
          fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
          fx_final: `${e.ZA3_INDFIM}%`,
          porcentagem_aplicada: `${e.ZA3_PERC}%`,
          vl_inicial: valorInicial,
          vl_final: valorFinal,
          vl_premio: dinero({ amount: valorPre, currency: "BRL" })
            .setLocale("pt-BR")
            .toFormat(),
        });
      });

      this.metaIndividualAgendamento = Math.round(metricas.data.meta);

      this.calculoDadosComissaoAgendamento = calculoDados;

      this.$toast.success("Prêmio Agendamento Leads gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async premioMktComparecimento() {
      this.mktComparecimento = true;
      this.metaIndividualComparecimento = 0;
      this.metaAteIndividualComparecimento = 0;
      this.calculoDadosComissaoComparecimento = [];

      let url = `${process.env.VUE_APP_BASE_API_URL}/metricasAgendamento`;

      this.titlePremioCalc = "Buscando Agendamentos...";

      const metricas = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        tipo: "C",
      });

      if (!metricas || metricas.status !== 200) {
        console.log("erro");
        return;
      }

      console.log(metricas);

      url = `${process.env.VUE_APP_BASE_API_URL}/dadosPremio`;

      const dadosPremio = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
        tipo: "G",
      });

      if (!dadosPremio || dadosPremio.status !== 200) {
        console.log("erro");
        return;
      }

      let dadosComissao = dadosPremio.data;
      this.calculoDadosComissao = [];

      let calculoDados = [];

      dadosComissao.forEach(e => {
        let valorInicial = Math.round(
          ((metricas.data.meta * e.ZA3_INDINI) / 100).toFixed(2),
        );

        let valorFinal = Math.round(
          ((metricas.data.meta * e.ZA3_INDFIM) / 100).toFixed(2),
        );

        valorFinal -= 1;

        if (this.metaAteIndividualComparecimento <= 0) {
          this.metaAteIndividualComparecimento = valorInicial - 1;
        }

        let range = Number(
          ((metricas.data.agendamentos * 100) / metricas.data.meta).toFixed(2),
        );

        let tagMax = "";
        if (range > e.ZA2_LIMITE) {
          tagMax = "Excedeu o limite de 120%";
        }

        let valorBase = Number(((e.ZXG_VLPRM * e.ZXG_PESO) / 100).toFixed(2));
        let valorPremio = Number(((valorBase * e.ZA3_PERC) / 100).toFixed(2));

        valorPremio = 50 - (valorPremio % 50) + valorPremio;

        this.totalGeralPremio += valorPremio;
        this.totalPremioIndividual += valorPremio;

        let valorPre = Number(valorPremio.toFixed(2).replace(".", ""));

        calculoDados.push({
          vl_vendido: metricas.data.agendamentos,
          fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
          fx_final: `${e.ZA3_INDFIM}%`,
          porcentagem_aplicada: `${e.ZA3_PERC}%`,
          vl_inicial: valorInicial,
          vl_final: valorFinal,
          vl_premio: dinero({ amount: valorPre, currency: "BRL" })
            .setLocale("pt-BR")
            .toFormat(),
        });
      });

      this.metaIndividualComparecimento = Math.round(metricas.data.meta);

      this.calculoDadosComissaoComparecimento = calculoDados;

      this.$toast.success(
        "Prêmio Comparecimento Leads agenda gerado com sucesso!",
        {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        },
      );
    },

    async premioProspeIndica() {
      this.prospeccaoIndicacao = true;
      this.metaIndividualIndicacao = 0;
      this.metaAteIndividualIndicacao = 0;
      this.calculoDadosComissaoIndicacao = [];

      this.itemsDetalhamento = [];
      this.showSkelekton = true;
      this.showDados = false;
      this.numberLines = 0;
      this.valorTotalDetalhamento = null;

      let url = `${process.env.VUE_APP_BASE_API_URL}/metricasIndicacao`;

      this.titlePremioCalc = "Buscando faturamento...";

      const metricas = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
      });

      if (!metricas || metricas.status !== 200) {
        console.log("erro");
        return;
      }

      url = `${process.env.VUE_APP_BASE_API_URL}/dadosPremio`;

      const dadosPremio = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
        tipo: "X",
      });

      if (!dadosPremio || dadosPremio.status !== 200) {
        console.log("erro");
        return;
      }

      url = `${process.env.VUE_APP_BASE_API_URL}/metasIndicacao`;

      const metasIndicacao = await axios.post(url, {
        tipo: "X",
        vendedor: this.vendedor,
        trimestre: this.trimestreEscolhido,
        ano: this.anoEscolhido,
      });

      if (!metasIndicacao || metasIndicacao.status !== 200) {
        console.log("erro");
        return;
      }

      let totalRealMeta = 0;

      metasIndicacao.data.map(e => {
        totalRealMeta += Number(
          ((e.dados.ZXE_META * e.dados.ZA3_PERC) / 100).toFixed(2),
        );
      });

      console.log(totalRealMeta);

      let dadosComissao = dadosPremio.data;
      this.calculoDadosComissao = [];
      this.metaAteIndividual = null;
      this.labelDivisaoIndividual = null;

      let calculoDados = [];

      dadosComissao.forEach(e => {
        let valorInicial = Math.round(
          ((metricas.data.totalPainel * e.ZA3_INDINI) / 100).toFixed(2),
        );

        let valorFinal = Math.round(
          ((metricas.data.totalPainel * e.ZA3_INDFIM) / 100).toFixed(2),
        );

        valorFinal -= 1;

        if (this.metaAteIndividualIndicacao <= 0) {
          this.metaAteIndividualIndicacao = valorInicial - 1;
        }

        let range = Number(
          (
            (metricas.data.totalIndicacao * 100) /
            metricas.data.totalPainel
          ).toFixed(2),
        );

        let tagMax = "";
        if (range > e.ZA2_LIMITE) {
          tagMax = "Excedeu o limite de 120%";
        }

        if (
          metricas.data.totalIndicacao <= valorFinal &&
          metricas.data.totalIndicacao >= valorInicial
        ) {
          let valorPremio = Number(
            ((totalRealMeta * e.ZA3_PERC) / 100).toFixed(2),
          );

          valorPremio = 50 - (valorPremio % 50) + valorPremio;

          this.totalGeralPremio += valorPremio;
          this.totalPremioIndividual += valorPremio;

          let valorPre = Number(valorPremio.toFixed(2).replace(".", ""));

          calculoDados.push({
            vl_vendido: metricas.data.totalIndicacao,
            fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: dinero({ amount: valorPre, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
          });
        } else {
          calculoDados.push({
            vl_vendido: metricas.data.totalIndicacao,
            fx_inicial: `${e.ZA3_INDINI}%`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: "-",
          });
        }
      });

      this.metaIndividualIndicacao = Math.round(metricas.data.totalPainel);

      this.calculoDadosComissaoIndicacao = calculoDados;

      this.$toast.success(
        "Prêmio prospecção Indicação Médica gerado com sucesso!",
        {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        },
      );
    },
    async premioProspeVisita() {
      this.prospeccaoVisitacao = true;

      this.itemsDetalhamento = [];
      this.showSkelekton = true;
      this.showDados = false;
      this.numberLines = 0;
      this.valorTotalDetalhamento = null;

      let url = `${process.env.VUE_APP_BASE_API_URL}/metricasVisitacao`;

      this.titlePremioCalc = "Buscando faturamento...";

      const metricas = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
      });

      if (!metricas || metricas.status !== 200) {
        console.log("erro");
        return;
      }

      url = `${process.env.VUE_APP_BASE_API_URL}/dadosPremio`;

      const dadosPremio = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
        tipo: "V",
      });

      if (!dadosPremio || dadosPremio.status !== 200) {
        console.log("erro");
        return;
      }

      url = `${process.env.VUE_APP_BASE_API_URL}/metasIndicacao`;

      const metasIndicacao = await axios.post(url, {
        tipo: "V",
        vendedor: this.vendedor,
        trimestre: this.trimestreEscolhido,
        ano: this.anoEscolhido,
      });

      if (!metasIndicacao || metasIndicacao.status !== 200) {
        console.log("erro");
        return;
      }

      let totalRealMeta = 0;

      metasIndicacao.data.map(e => {
        totalRealMeta += Number(
          ((e.dados.ZXE_META * e.dados.ZA3_PERC) / 100).toFixed(2),
        );
      });

      console.log(totalRealMeta);

      let dadosComissao = dadosPremio.data;
      this.calculoDadosComissao = [];
      this.metaAteIndividual = null;
      this.labelDivisaoIndividual = null;

      let calculoDados = [];

      dadosComissao.forEach(e => {
        let valorInicial = Math.round(
          ((metricas.data.totalPainel * e.ZA3_INDINI) / 100).toFixed(2),
        );

        let valorFinal = Math.round(
          ((metricas.data.totalPainel * e.ZA3_INDFIM) / 100).toFixed(2),
        );

        valorFinal -= 1;

        if (this.metaAteIndividualVisitacao <= 0) {
          this.metaAteIndividualVisitacao = valorInicial - 1;
        }

        let range = Number(
          (
            (metricas.data.totalVisitacao * 100) /
            metricas.data.totalPainel
          ).toFixed(2),
        );

        let tagMax = "";
        if (range > e.ZA2_LIMITE) {
          tagMax = "Excedeu o limite de 120%";
        }

        if (
          metricas.data.totalVisitacao <= valorFinal &&
          metricas.data.totalVisitacao >= valorInicial
        ) {
          let valorPremio = Number(
            ((totalRealMeta * e.ZA3_PERC) / 100).toFixed(2),
          );

          valorPremio = 50 - (valorPremio % 50) + valorPremio;

          this.totalGeralPremio += valorPremio;
          this.totalPremioIndividual += valorPremio;

          let valorPre = Number(valorPremio.toFixed(2).replace(".", ""));

          calculoDados.push({
            vl_vendido: metricas.data.totalVisitacao,
            fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: dinero({ amount: valorPre, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
          });
        } else {
          calculoDados.push({
            vl_vendido: metricas.data.totalVisitacao,
            fx_inicial: `${e.ZA3_INDINI}%`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: "-",
          });
        }
      });

      this.metaIndividualVisitacao = Math.round(metricas.data.totalPainel);

      this.calculoDadosComissaoVisitacao = calculoDados;

      this.$toast.success("Prêmio Visitação médcia gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async premioIndividual(vendedor) {
      this.itemsDetalhamento = [];
      this.andamento = 0;
      this.numberofEquipes = 0;
      const datas = this.trimestre.split("-");
      const dtInicial = `${this.anoEscolhido}${datas[0]}`;

      this.showSkelekton = true;
      this.showDados = false;
      this.numberLines = 0;
      this.valorTotalDetalhamento = null;

      let dados = {};
      let url = ``;

      this.titlePremioCalc = "Buscando faturamento...";

      this.titlePremioCalc = "Calculando prêmio individual...";

      console.log(Number(this.trimestreEscolhido));

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ZA3_INDINI,ZA3_INDFIM,ZA3_TIPO,ZA3_PERC,ZA2_LIMITE,ZA2_TIPO,ZA3_PBASE,ZXG_LOJA,ZA2_TPMETA,ZXG_HRTRAB,ZXG_QTDP,ZXG_PESO`,
        INNER: ` INNER JOIN ZXG010 ZXG WITH(NOLOCK) ON ZXG.D_E_L_E_T_=' ' AND ZXG_IDZA2=ZA2_ID AND ZXG_TRIMES='${Number(
          this.trimestreEscolhido,
        )}'
                 INNER JOIN ZA3010 ZA3 WITH(NOLOCK) ON ZA3.D_E_L_E_T_=' ' AND ZA3_IDREG=ZXG_ID `,
        WHERE: `ZA2_CODVEN='${vendedor.ZA2_CODVEN}' AND ZA2_ANO='${this.anoEscolhido}' AND ZA2_TIPO='I'`,
        TABELA: `ZA2`,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      let dadosComissao = [];
      this.calculoDadosComissao = [];
      this.metaAteIndividual = null;
      this.labelDivisaoIndividual = null;

      await axios
        .post(url, dados)
        .then(res => {
          dadosComissao = res.data;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      let calculoDados = [];
      let meta = 0;
      this.metaIndividual = null;

      let tri = "";
      if (dadosComissao.length >= 1) {
        meta = 0;
        if (dtInicial === `${this.anoEscolhido}0101`) {
          tri = `${this.anoEscolhido}01`;
        }
        if (dtInicial === `${this.anoEscolhido}0401`) {
          tri = `${this.anoEscolhido}02`;
        }
        if (dtInicial === `${this.anoEscolhido}0701`) {
          tri = `${this.anoEscolhido}03`;
        }
        if (dtInicial === `${this.anoEscolhido}1001`) {
          tri = `${this.anoEscolhido}04`;
        }

        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `ZXE_META`,
          WHERE: `ZXE_LOJA='${dadosComissao[0].ZXG_LOJA}' AND ZXE_TPCALC='${dadosComissao[0].ZA2_TPMETA}' AND ZXE_ANOTRI='${tri}' `,
          TABELA: `ZXE`,
        };

        if (
          dadosComissao[0].ZA2_TPMETA.trim() === "FN" &&
          dadosComissao[0].ZXG_HRTRAB > 0
        ) {
          dados.WHERE = `ZXE_LOJA='${dadosComissao[0].ZXG_LOJA}' AND ZXE_TPCALC='${dadosComissao[0].ZA2_TPMETA}' AND ZXE_ANOTRI='${tri}' AND ZXE_HRTRAB='${dadosComissao[0].ZXG_HRTRAB}'`;
        }

        if (vendedor.ZA2_CODVEN === "002344") {
          dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: `ZXE_META`,
            WHERE: `ZXE_LOJA='${dadosComissao[0].ZXG_LOJA}' AND ZXE_TPCALC='AS' AND ZXE_ANOTRI='${tri}'`,
            TABELA: `ZXE`,
          };
        }

        const valor = await axios.post(url, dados);
        if (dadosComissao[0].ZXG_QTDP < 1) {
          dadosComissao[0].ZXG_QTDP = 1;
        }

        if (vendedor.ZA2_CODVEN === "002344" && tri === "202304") {
          valor.data[0].ZXE_META = 20000;
        }

        let mt = Number(
          ((valor.data[0].ZXE_META * 3) / dadosComissao[0].ZXG_QTDP).toFixed(2),
        );

        meta = mt;

        this.metaIndividual = meta.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        this.metaAteIndividual = 0;

        dadosComissao.forEach(e => {
          let valorInicial = 0;
          let valorFinal = 0;

          valorInicial = Number(((meta * e.ZA3_INDINI) / 100).toFixed(2));

          if (this.metaAteIndividual <= 0) {
            this.metaAteIndividual = (valorInicial - 0.01).toLocaleString(
              "pt-BR",
              {
                style: "currency",
                currency: "BRL",
              },
            );
          }

          const proximoRange = e.ZA3_INDFIM + 0.01;
          valorFinal = Number(((meta * proximoRange) / 100 - 0.01).toFixed(2));

          let valorBase = Number(((meta * e.ZA3_PBASE) / 100).toFixed(2));

          let valorPremio = Number(((valorBase * e.ZA3_PERC) / 100).toFixed(2));

          valorPremio = 50 - (valorPremio % 50) + valorPremio;

          valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

          if (e.ZXG_PESO < 100) {
            valorPremio = 50 - (valorPremio % 50) + valorPremio;
          }

          this.totalGeralPremio += valorPremio;
          this.totalPremioIndividual += valorPremio;

          calculoDados.push({
            vl_vendido: this.valorTotalDetalhamento,
            fx_inicial: `${e.ZA3_INDINI}%`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
            vl_final: valorFinal.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
            vl_premio: valorPremio.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
          });
        });
      } else {
        this.dialogInfo = true;
        this.tituloCard = "Erro";
        this.mensagem = "Não achei dados para o cálculo do prêmio INDIVIDUAL!";
      }

      this.calculoDadosComissao = calculoDados;

      this.$toast.success("Prêmio individual gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async premioOpgrade(vendedor) {
      const datas = this.trimestre.split("-");
      const dtInicial = `${this.anoEscolhido}${datas[0]}`;

      let dados = {};
      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ZA3_INDINI,ZA3_INDFIM,ZA3_TIPO,ZA3_PERC,ZA2_LIMITE,ZA2_TIPO,ZA3_PBASE,ZXG_LOJA,ZA2_TPMETA,ZXG_HRTRAB,ZXG_QTDP,ZXG_PESO`,
        INNER: `  INNER JOIN ZXG010 ZXG WITH(NOLOCK) ON ZXG.D_E_L_E_T_=' ' AND ZXG_IDZA2=ZA2_ID AND ZXG_TRIMES='${Number(
          this.trimestreEscolhido,
        )}'
                  INNER JOIN ZA3010 ZA3 WITH(NOLOCK) ON ZA3.D_E_L_E_T_=' ' AND ZA3_IDREG=ZXG_ID `,
        WHERE: `ZA2_CODVEN='${vendedor.ZA2_CODVEN}' AND ZA2_ANO='${this.anoEscolhido}' AND ZA2_TIPO='O'`,
        TABELA: `ZA2`,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      let dadosComissao = [];
      this.calculoDadosComissaoOpGrade = [];
      this.metaAteIndividualOpGrade = null;
      this.labelDivisaoOpgrade = null;

      await axios
        .post(url, dados)
        .then(res => {
          dadosComissao = res.data;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      let calculoDados = [];
      let meta = 0;
      this.metaIndividualOpGrade = null;

      let tri = "";

      if (dadosComissao.length >= 1) {
        meta = 0;
        if (dtInicial === `${this.anoEscolhido}0101`) {
          tri = `${this.anoEscolhido}01`;
        }
        if (dtInicial === `${this.anoEscolhido}0401`) {
          tri = `${this.anoEscolhido}02`;
        }
        if (dtInicial === `${this.anoEscolhido}0701`) {
          tri = `${this.anoEscolhido}03`;
        }
        if (dtInicial === `${this.anoEscolhido}1001`) {
          tri = `${this.anoEscolhido}04`;
        }

        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `ZXE_META`,
          WHERE: `ZXE_LOJA='${dadosComissao[0].ZXG_LOJA}' AND ZXE_TPCALC='${dadosComissao[0].ZA2_TPMETA}' AND ZXE_ANOTRI='${tri}' `,
          TABELA: `ZXE`,
        };

        if (
          dadosComissao[0].ZA2_TPMETA.trim() === "FN" &&
          dadosComissao[0].ZXG_HRTRAB > 0
        ) {
          dados.WHERE = `ZXE_LOJA='${dadosComissao[0].ZXG_LOJA}' AND ZXE_TPCALC='${dadosComissao[0].ZA2_TPMETA}' AND ZXE_ANOTRI='${tri}' AND ZXE_HRTRAB='${dadosComissao[0].ZXG_HRTRAB}' `;
        }

        const valor = await axios.post(url, dados);

        console.log("valor da meta===> ", valor.data);

        if (dadosComissao[0].ZXG_QTDP < 1) {
          dadosComissao[0].ZXG_QTDP = 1;
        }
        let mt = Number(
          ((valor.data[0].ZXE_META * 3) / dadosComissao[0].ZXG_QTDP).toFixed(2),
        );

        meta = mt;

        this.metaIndividualOpGrade = meta.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        this.metaAteIndividualOpGrade = 0;

        dadosComissao.forEach(e => {
          let valorInicial = 0;
          let valorFinal = 0;

          valorInicial = Number(((meta * e.ZA3_INDINI) / 100).toFixed(2));

          if (this.metaAteIndividualOpGrade <= 0) {
            this.metaAteIndividualOpGrade = (
              valorInicial - 0.01
            ).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          }

          const proximoRange = e.ZA3_INDFIM + 0.01;
          valorFinal = Number(((meta * proximoRange) / 100 - 0.01).toFixed(2));

          let valorBase = Number(((meta * e.ZA3_PBASE) / 100).toFixed(2));

          let valorPremio = Number(((valorBase * e.ZA3_PERC) / 100).toFixed(2));

          valorPremio = 50 - (valorPremio % 50) + valorPremio;

          valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

          if (e.ZXG_PESO < 100) {
            valorPremio = 50 - (valorPremio % 50) + valorPremio;
          }

          this.totalGeralPremioOpGrade += valorPremio;
          this.totalPremioOpGrade += valorPremio;

          calculoDados.push({
            vl_vendido: this.valorTotalDetalhamentoOpGrade,
            fx_inicial: `${e.ZA3_INDINI}%`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
            vl_final: valorFinal.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
            vl_premio: valorPremio.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
          });
        });
      } else {
        this.dialogInfo = true;
        this.tituloCard = "Erro";
        this.mensagem = "Não achei dados para o cálculo do prêmio INDIVIDUAL!";
      }

      this.calculoDadosComissaoOpGrade = calculoDados;

      this.$toast.success("Prêmio OpGrade gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },

    async calculoEquipeProspeccao(dados) {
      if (dados.length <= 0) return;

      console.log("Calculo de premiação prospector");

      const datas = this.trimestre.split("-");

      const dtInicial = `${this.anoEscolhido}${datas[0]}`;

      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;
      let dadosUrl = {};
      let equipes = [];
      dados.forEach(e => {
        if (!equipes.includes(e.idEquipe)) {
          equipes.push(e.idEquipe);
        }
      });

      let dadosVendedor = dados;

      let dadosVendedoresEquipe = [];

      this.numberofEquipes = equipes.length;

      this.andamento = 0;

      const identador = 100 / equipes.length;

      for (const equipe of equipes) {
        dadosUrl = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "ZA7_CODVEN,ZA5_NOME,ZA7_IDCAB,ZA5_LOJA,ZA5_TIPO",
          INNER: `INNER JOIN ZA5010 ZA5 WITH(NOLOCK) ON (ZA5_COD=ZA7_IDCAB AND ZA5_ANO='${this.anoEscolhido}' AND ZA5.D_E_L_E_T_=' ')`,
          WHERE: `ZA7_IDCAB='${equipe}' AND ZA7.D_E_L_E_T_=' '`,
          TABELA: "ZA7",
        };

        await axios
          .post(url, dadosUrl)
          .then(async res => {
            let vendedores = "";

            res.data.forEach(e => {
              if (vendedores === "") {
                vendedores = `'${e.ZA7_CODVEN}'`;
              } else {
                vendedores += `,'${e.ZA7_CODVEN}'`;
              }
            });
            let meta = 0;
            let tri = "";
            if (dtInicial === `${this.anoEscolhido}0101`) {
              tri = `${this.anoEscolhido}01`;
            }
            if (dtInicial === `${this.anoEscolhido}0401`) {
              tri = `${this.anoEscolhido}02`;
            }
            if (dtInicial === `${this.anoEscolhido}0701`) {
              tri = `${this.anoEscolhido}03`;
            }
            if (dtInicial === `${this.anoEscolhido}1001`) {
              tri = `${this.anoEscolhido}04`;
            }

            dados = {
              EMPRESA: "01",
              FILIAL: "0101",
              FIELDS: `ZXE_META`,
              WHERE: `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='UN' AND ZXE_ANOTRI='${tri}'`,
              TABELA: `ZXE`,
            };

            const valor = await axios.post(url, dados);

            let mt = Number((valor.data[0].ZXE_META * 3).toFixed(2));

            meta = mt;

            dadosVendedoresEquipe.push({
              idEquipe: res.data[0].ZA7_IDCAB,
              nome: res.data[0].ZA5_NOME,
              vendedores,
              meta,
              loja: res.data[0].ZA5_LOJA,
            });
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });
      }

      if (dadosVendedoresEquipe.length <= 0) {
        this.dialogInfo = true;
        this.tituloCard = "Erro";
        this.mensagem = "Não achei vendedores para o cálculo do prêmio EQUIPE!";
        return;
      }

      this.totalGeralEquipes = 0;
      for (const equipe of dadosVendedoresEquipe) {
        let detalhamentoFatVendedores = [];

        this.titlePremioCalc = `Calculando faturamento ${equipe.nome}`;

        let urlNode = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

        let anomes = "";

        if (this.trimestre.split("-")[0].substr(0, 2) == "01") {
          anomes = `'${this.anoEscolhido}01','${this.anoEscolhido}02','${this.anoEscolhido}03'`;
        }
        if (this.trimestre.split("-")[0].substr(0, 2) == "04") {
          anomes = `'${this.anoEscolhido}04','${this.anoEscolhido}05','${this.anoEscolhido}06'`;
        }
        if (this.trimestre.split("-")[0].substr(0, 2) == "07") {
          anomes = `'${this.anoEscolhido}07','${this.anoEscolhido}08','${this.anoEscolhido}09'`;
        }
        if (this.trimestre.split("-")[0].substr(0, 2) == "10") {
          anomes = `'${this.anoEscolhido}10','${this.anoEscolhido}11','${this.anoEscolhido}12'`;
        }

        if (equipe.idEquipe === "000034") {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
          dadosUrl = {
            query: `SELECT SUM(FATURAMENTO_LIQUIDO) FATURAMENTO, 'AREA 4 ' VENDEDOR FROM DB_V_RECEITAS_BI WHERE ANOMES IN (${anomes}) AND LEFT(AREA,6)='000004' AND LEFT(VENDEDOR,6) <> '002828'`,
          };
        } else {
          dadosUrl = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: "VENDEDOR,INDICADOR,SUM(FATURAMENTO_LIQUIDO) FATURAMENTO",
            WHERE: `CHAVE_VENDEDOR IN (${equipe.vendedores})
                               AND PLANO_SAUDE<>'1'
                               AND APAC<>'S'
                               AND ANOMES IN (${anomes})
                               AND RELATORIO_BI<>'2' GROUP BY VENDEDOR,INDICADOR`,
            TABELA: "DB_V_RECEITAS_BI",
          };
        }

        const faturamentoVendedores = await axios
          .post(urlNode, dadosUrl)
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });

        const totalFaturamentoVendedores = faturamentoVendedores.data.reduce(
          (total, item) => total + item.FATURAMENTO,
          0,
        );

        this.totalGeralEquipes += totalFaturamentoVendedores;

        detalhamentoFatVendedores = faturamentoVendedores.data.map(e => {
          return {
            VENDEDOR: e.VENDEDOR,
            FATURAMENTO: e.FATURAMENTO.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
          };
        });

        const dadosComissao = dadosVendedor.filter(
          e => e.idEquipe === equipe.idEquipe,
        )[0].dadosComissao;
        let calculoDados = [];
        let metaAteEquipe = 0;

        this.labelDivisaoEquipe = null;

        dadosComissao.forEach(e => {
          let valorInicial = 0;
          let valorFinal = 0;

          valorInicial = Number(
            ((equipe.meta * e.ZA3_INDINI) / 100).toFixed(2),
          );

          if (metaAteEquipe <= 0) {
            metaAteEquipe = (valorInicial - 0.01).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          }

          const proximoRange = e.ZA3_INDFIM + 0.01;
          valorFinal = Number(
            ((equipe.meta * proximoRange) / 100 - 0.01).toFixed(2),
          );

          let valorMaxPorc = (totalFaturamentoVendedores * 100) / equipe.meta;

          let tagMax = "";
          if (valorMaxPorc > e.ZA2_LIMITE) {
            tagMax = "Excedeu o limite de 120%";
          }

          if (
            totalFaturamentoVendedores <= valorFinal &&
            totalFaturamentoVendedores >= valorInicial
          ) {
            let valorAlcancadoPremioIndividual = dadosVendedor.filter(
              d => d.idEquipe === e.ZA8_IDEQP,
            )[0].premio;

            let valorPremio = Number(
              ((valorAlcancadoPremioIndividual * e.ZA3_PERC) / 100).toFixed(2),
            );

            valorPremio = 50 - (valorPremio % 50) + valorPremio;

            valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

            if (e.ZXG_PESO < 100) {
              valorPremio = 50 - (valorPremio % 50) + valorPremio;
            }

            valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

            this.totalGeralPremio += valorPremio;
            this.totalPremioEquipe += valorPremio;

            calculoDados.push({
              vl_vendido: totalFaturamentoVendedores.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              vl_vendidoCurr: totalFaturamentoVendedores,
              fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
              fx_final: `${e.ZA3_INDFIM}%`,
              porcentagem_aplicada: `${e.ZA3_PERC}%`,
              vl_inicial: valorInicial.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_final: valorFinal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premio: valorPremio.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premioCurr: valorPremio,
            });
          } else {
            calculoDados.push({
              vl_vendido: totalFaturamentoVendedores.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              vl_vendidoCurr: totalFaturamentoVendedores,
              fx_inicial: `${e.ZA3_INDINI}%`,
              fx_final: `${e.ZA3_INDFIM}%`,
              porcentagem_aplicada: `${e.ZA3_PERC}%`,
              vl_inicial: valorInicial.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_final: valorFinal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premio: "-",
              vl_premioCurr: 0,
            });
          }
        });

        this.dadosDoCalculoEquipe.push({
          nome: equipe.nome,
          meta: equipe.meta.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
          detalhamento: detalhamentoFatVendedores,
          totalFaturamento: totalFaturamentoVendedores,
          metaAte: metaAteEquipe,
          calculo: calculoDados,
          loja: equipe.loja,
        });

        this.andamento += identador;
      }

      this.calculoDadosComissaoEquipe = [];

      this.$toast.success("Prêmio de equipe gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async calculoEquipe(dados, dtInicial) {
      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;
      let dadosUrl = {};
      let equipes = [];
      let dadosVendedor = dados;
      dados.forEach(e => {
        if (!equipes.includes(e.ZA8_IDEQP)) {
          equipes.push(e.ZA8_IDEQP);
        }
      });

      let dadosVendedoresEquipe = [];

      this.numberofEquipes = equipes.length;

      this.andamento = 0;

      const identador = 100 / equipes.length;

      for await (const equipe of equipes) {
        dadosUrl = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "ZA7_CODVEN,ZA5_COD,ZA5_NOME,ZA7_IDCAB,ZA5_LOJA,ZA5_TIPO",
          INNER: `INNER JOIN ZA5010 ZA5 WITH(NOLOCK) ON (ZA5_COD=ZA7_IDCAB AND ZA5_ANO='${this.anoEscolhido}' AND ZA5.D_E_L_E_T_=' ')`,
          WHERE: `ZA7_IDCAB='${equipe}' AND ZA7.D_E_L_E_T_=' '`,
          TABELA: "ZA7",
        };

        await axios
          .post(url, dadosUrl)
          .then(async res => {
            if (res.data.length > 0) {
              let vendedores = "";

              res.data.forEach(e => {
                if (vendedores === "") {
                  vendedores = `'${e.ZA7_CODVEN}'`;
                } else {
                  vendedores += `,'${e.ZA7_CODVEN}'`;
                }
              });
              let meta = 0;
              let tri = "";
              if (dtInicial === `${this.anoEscolhido}0101`) {
                tri = `${this.anoEscolhido}01`;
              }
              if (dtInicial === `${this.anoEscolhido}0401`) {
                tri = `${this.anoEscolhido}02`;
              }
              if (dtInicial === `${this.anoEscolhido}0701`) {
                tri = `${this.anoEscolhido}03`;
              }
              if (dtInicial === `${this.anoEscolhido}1001`) {
                tri = `${this.anoEscolhido}04`;
              }

              dados = {
                EMPRESA: "01",
                FILIAL: "0101",
                FIELDS: `ZXE_META`,
                WHERE: `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='${res.data[0].ZA5_TIPO}' AND ZXE_ANOTRI='${tri}'`,
                TABELA: `ZXE`,
              };

              if (
                (this.vendedor === "002163" || this.vendedor === "002344") &&
                res.data[0].ZA7_IDCAB === "000034"
              ) {
                dados.WHERE = `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='AS' AND ZXE_ANOTRI='${tri}'`;
              }

              if (res.data[0].ZA5_TIPO.trim() === "PF") {
                dados.WHERE = `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='FN' AND ZXE_HRTRAB='44' AND ZXE_ANOTRI='${tri}'`;
              }

              if (res.data[0].ZA5_TIPO.trim() === "AD") {
                dados.WHERE = `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='UN' AND ZXE_TIPO='FM' AND ZXE_ANOTRI='${tri}'`;
              }

              if (
                this.vendedor === "003785" &&
                res.data[0].ZA7_IDCAB === "000050"
              ) {
                dados.WHERE = `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='FN' AND ZXE_HRTRAB='44' AND ZXE_ANOTRI='${tri}'`;
              }

              if (
                (this.vendedor === "002344" || this.vendedor === "002974") &&
                res.data[0].ZA7_IDCAB === "000034"
              ) {
                dados.WHERE = `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='FN' AND ZXE_HRTRAB='44' AND ZXE_ANOTRI='${tri}'`;
              }

              let valor = await axios.post(url, dados);

              let mt = 0;

              if (valor.data.length > 0) {
                mt = Number((valor.data[0].ZXE_META * 3).toFixed(2));
              }

              meta = mt;

              dadosVendedoresEquipe.push({
                idEquipe: res.data[0].ZA7_IDCAB,
                nome: res.data[0].ZA5_NOME,
                vendedores,
                tipo: res.data[0]?.ZA5_TIPO.trim(),
                meta,
                loja: res.data[0]?.ZA5_LOJA.trim(),
              });
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });
      }

      if (dadosVendedoresEquipe.length <= 0) {
        this.dialogInfo = true;
        this.tituloCard = "Erro";
        this.mensagem = "Não achei vendedores para o cálculo do prêmio EQUIPE!";
        return;
      }

      this.totalGeralEquipes = 0;
      for (const equipe of dadosVendedoresEquipe) {
        let detalhamentoFatVendedores = [];

        this.titlePremioCalc = `Calculando faturamento ${equipe.nome}`;

        const dadosComissao = dadosVendedor.filter(
          e => e.ZA8_IDEQP === equipe.idEquipe,
        );

        let calculoDados = [];
        let metaAteEquipe = 0;

        this.labelDivisaoEquipe = null;

        if (dadosComissao[0].ZXG_QTDP < 1) {
          dadosComissao[0].ZXG_QTDP = 1;
        }

        equipe.meta = Number(
          (equipe.meta / dadosComissao[0].ZXG_QTDP).toFixed(2),
        );

        dadosComissao.forEach(e => {
          let valorInicial = 0;
          let valorFinal = 0;

          valorInicial = Number(
            ((equipe.meta * e.ZA3_INDINI) / 100).toFixed(2),
          );

          if (metaAteEquipe <= 0) {
            metaAteEquipe = (valorInicial - 0.01).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          }

          const proximoRange = e.ZA3_INDFIM + 0.01;
          valorFinal = Number(
            ((equipe.meta * proximoRange) / 100 - 0.01).toFixed(2),
          );

          let valorBase = Number(
            ((equipe.meta * e.ZA3_PBASE) / 100).toFixed(2),
          );

          let valorPremio = Number(((valorBase * e.ZA3_PERC) / 100).toFixed(2));

          valorPremio = 50 - (valorPremio % 50) + valorPremio;

          valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

          if (e.ZXG_PESO < 100) {
            valorPremio = 50 - (valorPremio % 50) + valorPremio;
          }

          // valorPremio = Number(((valorPremio * e.ZA2_PESO) / 100).toFixed(2));

          this.totalGeralPremio += valorPremio;
          this.totalPremioEquipe += valorPremio;

          calculoDados.push({
            vl_vendido: 0,
            vl_vendidoCurr: 0,
            fx_inicial: `${e.ZA3_INDINI}%`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
            vl_final: valorFinal.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
            vl_premio: valorPremio.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
            vl_premioCurr: valorPremio,
          });
        });

        this.dadosDoCalculoEquipe.push({
          nome: equipe.nome,
          meta: equipe.meta.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
          detalhamento: detalhamentoFatVendedores,
          totalFaturamento: 0,
          metaAte: metaAteEquipe,
          calculo: calculoDados,
          loja: equipe.loja,
        });

        this.andamento += identador;
      }

      this.calculoDadosComissaoEquipe = [];

      this.$toast.success("Prêmio de equipe gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async buscaVendedores() {
      this.vendedores = [];
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `DISTINCT ZA2_CODVEN,A3_NOME,A3_ZTIPO,Z0_DESC,ZMA_DESCRI`,
        INNER: ` INNER JOIN SA3010 SA3 WITH(NOLOCK) ON (A3_COD=ZA2_CODVEN AND SA3.D_E_L_E_T_=' ')
                 INNER JOIN SZ0010 SZ0 WITH(NOLOCK) ON (Z0_COD=A3_ZTIPO AND SZ0.D_E_L_E_T_=' ')
                 INNER JOIN ZMA010 ZMA WITH(NOLOCK) ON (ZMA_IDAREA=A3_ZZAREA AND ZMA.D_E_L_E_T_=' ')`,
        WHERE: ``,
        TABELA: `ZA2`,
      };
      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.vendedores = res.data.map(e => {
            return {
              text: `${e.ZA2_CODVEN}-${e.A3_NOME}`,
              value: e,
            };
          });
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    async buscaPessoas() {
      this.dadosVendedoresGeral = [];
      this.vendedores = [];
      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;
      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `A3_EMAIL,A3_NOME,ZA2_PESO,ZA2_FONOS,ZA2_LOJA,ZA2_CODVEN,ZA2_ID,ZA2_TIPO,ZA2_META1,ZA2_META2,ZA2_META3,ZA2_META4,ZA2_QTDP,ZA2_ANO,ZA2_PROFIS,ZA9_DESCRI,ZA2_SEGVEN`,
        INNER: ` INNER JOIN SA3010 SA3 WITH(NOLOCK) ON
                                SA3.D_E_L_E_T_=' '
                                AND A3_COD=ZA2_CODVEN
                                --AND A3_MSBLQL='2'

                  LEFT JOIN SZ0010 SZ0 WITH(NOLOCK) ON
                                SZ0.D_E_L_E_T_=' '
                                AND Z0_COD=A3_ZTIPO

                  LEFT JOIN ZA9010 ZA9 WITH(NOLOCK) ON
                                ZA9.D_E_L_E_T_=' '
                                AND ZA9_COD=ZA2_PROFIS `,
        WHERE: ``,
        TABELA: `ZA2`,
      };
      if (this.user.isAdmin) {
        dados.WHERE = `ZA2_ANO='${this.anoEscolhido}' ORDER BY SA3.A3_NOME `;
      } else {
        if (this.user.vendedoresPremiacao.length <= 0) {
          this.$toast.error(
            "Não tem vendedor(ZS1_VEND) preenchido no cadastro de usuário!",
            {
              position: "bottom-center",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            },
          );
          return;
        } else {
          let codVendedores = "";

          this.user.vendedoresPremiacao.map(e => {
            codVendedores += `'${e.trim()}',`;
          });

          const dados1 = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: `ZAC_CODVEN`,
            INNER: `INNER JOIN ZAC010 ZAC WITH(NOLOCK) ON ZAC.D_E_L_E_T_=' ' AND ZAC_IDCAB=ZAB_COD `,
            WHERE: `ZAB_CODVEN='${this.user.cod_vendedor}' `,
            TABELA: "ZAB",
          };

          const alcadaVendedores = await axios.post(url, dados1);

          if (alcadaVendedores.data.length > 0) {
            alcadaVendedores.data.map(e => {
              codVendedores += `'${e.ZAC_CODVEN}',`;
            });
          }

          codVendedores = codVendedores.substring(0, codVendedores.length - 1);

          codVendedores += `,'${this.user.cod_vendedor}'`;

          dados.WHERE = `ZA2_ANO='${this.anoEscolhido}' AND ZA2_CODVEN IN (${codVendedores}) ORDER BY SA3.A3_NOME `;
        }
      }

      await axios.post(url, dados).then(res => {
        this.dadosVendedoresGeral = res.data;
        const arrayUnique = [
          ...new Set(res.data.map(e => `${e.ZA2_CODVEN}-${e.A3_NOME}`)),
        ];

        this.vendedores = arrayUnique.map(e => {
          return {
            text: e,
            value: e.substring(0, 6),
          };
        });
      });
    },

    async buscaAnos() {
      const anosSub = sub(new Date(), { years: 2 });

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anosSub, { years: cont }).getFullYear() <=
          new Date().getFullYear()
        ) {
          this.anos.push(add(anosSub, { years: cont }).getFullYear());
        }
      }

      this.anos = this.anos.reverse();
      this.loadingAnos = false;
    },
    async chamaDialogEmail() {
      const profissional = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === this.vendedor,
      );

      this.emailEnvio = profissional[0].A3_EMAIL.trim();
      this.dialogEmail = true;
    },

    async premioAdm(item) {
      console.log(item);

      this.itemsDetalhamento = [];
      this.showSkelekton = true;
      this.showDados = false;
      this.numberLines = 0;
      this.valorTotalDetalhamento = null;
      let url = `${process.env.VUE_APP_BASE_API_URL}/metricasAdm`;

      this.titlePremioCalc = "Buscando faturamento...";

      const metricas = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestre,
      });

      if (!metricas || metricas.status !== 200) {
        console.log("erro");
        return;
      }

      const profissional = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === this.vendedor && e.ZA2_TIPO === "A",
      );

      console.log(profissional);

      this.titlePremioCalc = "Buscando dados do prêmio...";

      url = `${process.env.VUE_APP_BASE_API_URL}/dadosPremio`;

      const dadosPremio = await axios.post(url, {
        trimestre: this.trimestreEscolhido,
        ano: this.anoEscolhido,
        vendedor: this.vendedor,
        tipo: "A",
      });

      let dadosComissao = dadosPremio.data;
      this.calculoDadosComissao = [];
      this.metaAteIndividual = null;
      this.labelDivisaoIndividual = null;

      let calculoDados = [];

      dadosComissao.forEach(e => {
        let valorInicial = Number(
          ((metricas.data.metaTotal * e.ZA3_INDINI) / 100).toFixed(2),
        );
        // dinero({amount: metricas.data.metaTotal * 100, currency: "BRL"}).percentage(e.ZA3_INDINI).setLocale("pt-BR").toFormat();

        let valorFinal = Number(
          (
            (metricas.data.metaTotal * (e.ZA3_INDFIM + 0.01)) / 100 -
            0.01
          ).toFixed(2),
        );

        if (this.metaAteIndividual <= 0) {
          const valor = Number(
            (valorInicial - 0.01).toFixed(2).replace(".", ""),
          );
          this.metaAteIndividual = dinero({ amount: valor, currency: "BRL" })
            .setLocale("pt-BR")
            .toFormat();
        }

        let tagMax = "";
        if (Number(metricas.data.range.toFixed(2)) > e.ZA2_LIMITE) {
          tagMax = "Excedeu o limite de 120%";
        }

        let valorBase = Number(((e.ZA2_SALARI * e.ZXG_PESO) / 100).toFixed(2));
        let valorPremio = Number(((valorBase * e.ZA3_PERC) / 100).toFixed(2));

        valorPremio = 50 - (valorPremio % 50) + valorPremio;

        valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

        if (e.ZXG_PESO < 100) {
          valorPremio = 50 - (valorPremio % 50) + valorPremio;
        }

        this.totalGeralPremio += valorPremio;
        this.totalPremioIndividual += valorPremio;

        let valorVendido = Number(
          metricas.data.faturamentoTotal.toFixed(2).replace(".", ""),
        );
        let valorIni = Number(valorInicial.toFixed(2).replace(".", ""));
        let valorFim = Number(valorFinal.toFixed(2).replace(".", ""));
        let valorPre = Number(valorPremio.toFixed(2).replace(".", ""));

        calculoDados.push({
          vl_vendido: dinero({ amount: valorVendido, currency: "BRL" })
            .setLocale("pt-BR")
            .toFormat(),
          fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
          fx_final: `${e.ZA3_INDFIM}%`,
          porcentagem_aplicada: `${e.ZA3_PERC}%`,
          vl_inicial: dinero({ amount: valorIni, currency: "BRL" })
            .setLocale("pt-BR")
            .toFormat(),
          vl_final: dinero({ amount: valorFim, currency: "BRL" })
            .setLocale("pt-BR")
            .toFormat(),
          vl_premio: dinero({ amount: valorPre, currency: "BRL" })
            .setLocale("pt-BR")
            .toFormat(),
        });
      });

      const metaindividual = Number(
        metricas.data.metaTotal.toFixed(2).replace(".", ""),
      );

      this.metaIndividual = dinero({ amount: metaindividual, currency: "BRL" })
        .setLocale("pt-BR")
        .toFormat();

      this.calculoDadosComissao = calculoDados;

      this.premioAdmin = true;
    },

    async handleGerarPdf(somenteDados) {
      this.snackbar_error = false;
      const url = `${process.env.VUE_APP_BASE_API_URL}/premio/pdf`;
      let tableEquipe = [];
      let plus = false;

      if (this.countEquipes <= 1 && this.numberofEquipes <= 1) {
        tableEquipe = this.premioAdmin
          ? this.dadosDoCalculoEquipe.push({
              meta: 0,
              metaAte: 0,
              detalhamento: [],
            })
          : this.dadosDoCalculoEquipe.map(e => {
              return {
                nome: e.nome.trim(),
                metaAte: e.metaAte,
                meta: e.meta,
                totalFaturamento: e.totalFaturamento,
                premio: e.premio,
                calculo: e.calculo,
              };
            });
      } else {
        plus = true;
        this.dadosDoCalculoEquipe.map(
          e =>
            (e.premio = e.calculo.reduce(
              (total, item) => total + item.vl_premioCurr,
              0,
            )),
        );

        tableEquipe = this.dadosDoCalculoEquipe.map(e => {
          return {
            nome: e.nome.trim(),
            metaAte: e.metaAte,
            meta: e.meta,
            totalFaturamento: e.totalFaturamento,
            premio: e.premio,
            calculo: e.calculo,
          };
        });
      }

      const dados = {
        tabelas: [
          {
            title: "Prêmio Individual",
            meta: this.metaIndividual,
            metaAte: this.metaAteIndividual,
            tabela: this.calculoDadosComissao,
            equipe: false,
          },
          {
            title: "Prêmio Opgrade",
            meta: this.metaIndividualOpGrade,
            metaAte: this.metaAteIndividualOpGrade,
            tabela: this.calculoDadosComissaoOpGrade,
            equipe: false,
          },
          {
            title: "Prêmio indicação médica",
            meta: this.metaIndividualIndicacao,
            metaAte: this.metaAteIndividualIndicacao,
            tabela: this.calculoDadosComissaoIndicacao,
            equipe: false,
          },
          {
            title: "Prêmio Visitação",
            meta: this.metaIndividualVisitacao,
            metaAte: this.metaAteIndividualVisitacao,
            tabela: this.calculoDadosComissaoVisitacao,
            equipe: false,
          },
          {
            title: "Prêmio Agendamento Leads",
            meta: this.metaIndividualAgendamento,
            metaAte: this.metaAteIndividualAgendamento,
            tabela: this.calculoDadosComissaoAgendamento,
            equipe: false,
          },
          {
            title: "Prêmio Comparecimento Agenda Leads",
            meta: this.metaAteIndividualComparecimento,
            metaAte: this.metaAteIndividualComparecimento,
            tabela: this.calculoDadosComissaoComparecimento,
            equipe: false,
          },
        ],
        totalPremioIndividual: this.totalPremioIndividual.toLocaleString(
          "pt-BR",
          { style: "currency", currency: "BRL" },
        ),
        totalPremioOpGrade: this.totalPremioOpGrade.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        totalPremioEquipe: this.totalPremioEquipe.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        totalPremioGeral: this.totalPremioGeral.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        totalRefinamento: this.totalRefinamento,
        obsRefinamento: this.obsRefinamento?.trim(),
        plus,
        nomeVendedor: `${this.labelBanner1}`,
      };

      if (tableEquipe.length > 0) {
        tableEquipe.map(e => {
          dados.tabelas.push({
            title: e.nome,
            meta: e.meta,
            metaAte: e.metaAte,
            tabela: e.calculo,
            equipe: false,
          });
        });
      }

      dados.informativo = true;

      if (somenteDados) return dados;

      try {
        const res = await axios.post(url, dados, { responseType: "blob" });
        const browserTab = window.open(URL.createObjectURL(res.data), "_blank");

        if (browserTab) {
          browserTab.focus();
        } else {
          this.error = "pop-up do arquivo PDF bloqueada pelo navegador";
          this.snackbar_error = true;
        }
      } catch (error) {
        this.error = error;
      }
    },
    async handleEnviaEmail() {
      if (!this.emailEnvio) return;

      const profissional = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === this.vendedor,
      );

      this.snackbar_error = false;

      const dadosPdf = await this.handleGerarPdf(true);

      const dados = {
        nome: profissional[0].A3_NOME.trim(),
        email: this.emailEnvio,
        titulo: "Premiação trimestral",
        dadosPdf,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/enviaPremio`;

      this.aguardarEnvio = true;

      await axios.post(url, dados).then(() => {
        this.dialogEmail = false;
        this.emailEnvio = null;
        this.aguardarEnvio = false;
        this.$toast.success("E-mail enviado com sucesso!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      });
    },
  },
  mounted() {
    this.buscaAnos();

    // this.buscaVendedores();
  },
};
</script>

<style scoped>
.vlpremio {
  font-size: 11rem;
}
</style>
